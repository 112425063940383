// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import * as bootstrap from 'bootstrap'
import swal from 'sweetalert'
// import utils from '@/utils/utils'
import Datepicker from 'vue3-datepicker'

import { accountFactory } from '@/http/account'
import { reportFactory } from '@/http/report'

@Options({
  components: {
    Header,
    Datepicker
  },
  data() {
    return {
      accounts: [],
      autocomplete: '',
      param: {
        acc_account_id: null,
        date_from: null,
        date_to: null
      }
    }
  },
  methods: {
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    exportBalanceSheet: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportBalanceSheet(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportIncomeSummary: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportIncomeSummary(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportPrepaidIncome: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportPrepaidIncome(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportPettyCashAdvance: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportPettyCashAdvance(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportCashFlow: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportCashFlow(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    openCashflow: function() {
      this.cashflowModal.show();
    },
    exportCashFlowAccount: function() {
      var param = {
        date_from: null,
        date_to: null,
        acc_account_id: ''
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      if (this.param.acc_account_id) {
        param.acc_account_id = this.param.acc_account_id
      }
      Promise.resolve(reportFactory.exportCashFlowAccount(param))
      .then(() => {
        this.cashflowModal.hide()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    setAccountID(search:any){
      this.param.acc_account_id = search.id
      this.accounts = [];
    },
    async getSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(accountFactory.search(keyword))
        .then(result => {
          this.accounts = result.data.accounts
          this.autocomplete = type
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.accounts = []
        })
      } else {
        this.accounts = []
      }
    },
  },
  async mounted () {

    var cashflowModal = document.getElementById('cashflowModal')
    if (cashflowModal != null) {
      this.cashflowModal = new bootstrap.Modal(cashflowModal)
    }
    this.cashflowModal.hide()
  },
  computed: {

  }
})

export default class Report extends Vue {}
