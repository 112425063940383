<template>
  <Header title="Cetak Laporan" link1="Akuntansi" link2="Cetak Laporan"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="text-secondary d-inline">Cetak Laporan</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row p-2">
            <div class="col-3"></div>
            <div class="col-md-3">
              <label for="">Dari Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_from" style="display:inherit;width:auto;"/>
            </div>
            <div class="col-md-3">
              <label for="">Sampai Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_to" style="display:inherit;width:auto;"/>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row text-center">
            <div class="card-group">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Neraca</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportBalanceSheet()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Laba Rugi</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportIncomeSummary()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Pendapatan Diterima di Muka</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportPrepaidIncome()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Kas Bon</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportPettyCashAdvance()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Arus Kas</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportCashFlow()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Arus Kas Per Akun</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="openCashflow()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="cashflowModal" class="modal" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pilih Akun</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3">
            <div class="col-md-12 mb-3">
              <label for="">Kode Akun :</label>
              <input placeholder="No Akun" v-model="param.acc_account_id" class="text-primary form-select" type="text" autocomplete="off" @input="getSuggestions(param.acc_account_id, 'search')" @focus="modal = true">
              <div v-if="accounts.length > 0 && autocomplete == 'search' && param.acc_account_id.length > 3">
                <ul class="container-autocomplete">
                    <li class="item-autocomplete" v-for="account in accounts" :key="account.id" @click="setAccountID(account)">{{account.id}} - {{ account.name }}</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
          <button type="button" class="btn btn-primary" @click="exportCashFlowAccount()"> <ion-icon name="download-outline"></ion-icon> Export</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script language="ts" src="./scripts/report.ts"></script>
